<template>
<div id="goaltree" class="scrollbar"> 
      <div id="reviewalgo_tem" class="reviewalgo">
        <p>哇，你的目标真宏伟啊*****</p>
      </div>
       <div id="add_goal_area">
        <!-- <button id="click_add_input_goal_div">点击添加目标</button> -->
        <input id="add_goal_text" type="text" placeholder="Please input your goal">
    <select name="goal_socer_select" id="goal_socer_select">
     <option value="5">重要等级5</option>
     <option value="4">重要等级4</option>
     <option value="3">重要等级3</option>
     <option value="2">重要等级2</option>
     <option value="1">重要等级1</option>
     </select>
    <button id="add_goal" class="add_goal">添加目标</button>
       </div>

    <div class="force-overflow">
    <div  class="list" id="all_unfinished_task_shown_area"  >
      <div id="goal_shown_and_edit" class="goal_shown_and_edit">
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios'
import configure_options from '../configure'
const $ = require('jquery');
window.$ = $;
var all_unfinished_tasks = []
//var all_unfinished_tasks_tree = {}
// 生成goal-project-task三层的列表div
function create_goal_project_task_div(){
    // get_all_unfinished_tasks_list()
    // 创建all_goals的列表
    // console.log("---------print all_unfinished_tasks-----------")
    // console.log(all_unfinished_tasks)
    var goal_ul = document.createElement("ul")
    // project = [];
    // for (let i = 0; i < all_unfinished_tasks.length; i++) {
    //     var this_goal_name = all_unfinished_tasks.Name
    //     var projects_in_this_goal = all_unfinished_tasks[i].Allprojectsingoal
    //     for (let j = 0; j < projects_in_this_goal.length; j++) {
    //         project.push(projects_in_this_goal[j].Name)
    //     }
    //     console.log("+++++++++我正在输出Project++++++++++")
    //     console.log(project)
    // }


    //第一层循环，对所有的Goal 
    for (let i = 0; i < all_unfinished_tasks.length; i++) {
        // all_goals.push(all_unfinished_tasks[i].Name)
        // get one goal
        var temp_goal =  all_unfinished_tasks[i].Name
        var goal_priority = all_unfinished_tasks[i].Priority
        // console.log("!!!!!!!!! printint goal !!!!!!!!!!")
        // console.log(temp_goal)
        // var project = get_no_repeat_project(all_tasks_in_one_project)
        // 输出当下Goal里的所有Projects到一个array
        var project = []
        var projects_in_this_goal = all_unfinished_tasks[i].Allprojectsingoal
        for (let j = 0; j < projects_in_this_goal.length; j++) {
            project.push(projects_in_this_goal[j].Name)
        }
        // console.log("!!!!!!!!! printint project !!!!!!!!!!")
        // console.log(projects_in_this_goal)

        // 输出当下Goal里的所有task到一个

        // var all_tasks_in_one_project = all_unfinished_tasks[i].Alltasksingoal
        // console.log("!!!!!!!!! printint alltaskinonegoal !!!!!!!!!!")
        // console.log(all_tasks_in_one_project)
        var goalcode = all_unfinished_tasks[i].Goalcode.split(" ")[0]

        // 创建Goal的DIV
        var goal_li = document.createElement("li")
        goal_li.setAttribute("class","goal_li")
        var goal_span = document.createElement("span")
        goal_span.textContent = temp_goal
        goal_span.setAttribute("goal_code", `${goalcode}`)
        goal_span.setAttribute("id",`${goalcode}`)
        var add_project2goal_button = document.createElement("input")
        add_project2goal_button.setAttribute("class","add_project2goal_button")
        console.log('打印目标')
        console.log(`${temp_goal}`)
        add_project2goal_button.setAttribute("id",`${temp_goal}`.hashCode())
        add_project2goal_button.setAttribute("type","image")
        add_project2goal_button.setAttribute("width","12")
        add_project2goal_button.setAttribute("height","12")
        add_project2goal_button.setAttribute("src","https://test-1255367272.cos.ap-chengdu.myqcloud.com/plus.svg")
        
        
        var finish_goal = document.createElement("button");
        finish_goal.setAttribute("class","finish_goal_class");
        finish_goal.setAttribute("goalcode",`${goalcode}`);
        finish_goal.setAttribute("goalname",`${temp_goal}`);
        finish_goal.setAttribute("priority",`${goal_priority}`);
        finish_goal.innerHTML = configure_options.finish_goal_project_button;

        var giveup_goal = document.createElement("button");
        giveup_goal.setAttribute("class","giveup_goal_class");
        giveup_goal.setAttribute("goalcode",`${goalcode}`);
        giveup_goal.setAttribute("goalname",`${temp_goal}`);
        giveup_goal.setAttribute("priority",`${goal_priority}`);
        giveup_goal.innerHTML = configure_options.giveup_goal_project_button;
        
        goal_li.appendChild(goal_span)
        goal_li.appendChild(add_project2goal_button)
        if (project.length == 0) {
            goal_li.appendChild(finish_goal)
            goal_li.appendChild(giveup_goal) 
        }
       
        // goal_li.appendChild(project_input)

        // 第二层循环，对第一个Goal下的所有Projects
        for (let j = 0; j < project.length; j++) {
            
             // 得到一个project下的所有tasks
            var all_tasks_in_one_project =  all_unfinished_tasks[i].Allprojectsingoal[j].Alltasksinproject
            // console.log("!!!!!!!!! printint all tasks in one project !!!!!!!!!!")
            // console.log(all_tasks_in_one_project)

            var project_ul = document.createElement("ul")
            var project_li = document.createElement("li")
            project_li.setAttribute("class","project_li")
            var project_span = document.createElement("span")
            var add_task2project_button = document.createElement("input")
            add_task2project_button.setAttribute("class","add_task2project_button")
            add_task2project_button.setAttribute("id",`${temp_goal}_${project[j]}`.hashCode())
            add_task2project_button.setAttribute("type","image")
            add_task2project_button.setAttribute("width","12")
            add_task2project_button.setAttribute("height","12")
            add_task2project_button.setAttribute("src","https://test-1255367272.cos.ap-chengdu.myqcloud.com/plus.svg")
            
            var finish_project = document.createElement("button");
            finish_project.setAttribute("class","finish_project_class");
            finish_project.setAttribute("goalcode",`${goalcode}`)
            finish_project.setAttribute("projectname",`${project[j]}`)
            finish_project.innerHTML = configure_options.finish_goal_project_button;
    
            var giveup_project = document.createElement("button");
            giveup_project.setAttribute("class","giveup_project_class");
            giveup_project.setAttribute("goalcode",`${goalcode}`)
            giveup_project.setAttribute("projectname",`${project[j]}`)
            giveup_project.innerHTML = configure_options.giveup_goal_project_button;
            
            project_span.textContent =project[j]
            project_li.appendChild(project_span)
            project_li.appendChild(add_task2project_button)

            if (all_tasks_in_one_project == null) {
                project_li.appendChild(finish_project)
                project_li.appendChild(giveup_project) 
            }

            project_ul.appendChild(project_li)
            goal_li.appendChild(project_ul)

            var task_ul = document.createElement("ul");
            task_ul.setAttribute("id",`${temp_goal}_${project[j]}`)
        //get one project

       
        // 第三层循环，对一个Project下的所有tasks
        if ( all_tasks_in_one_project == null ) {
            console.log('all_tasks_in_one_project == null')
        }
        else{

        
           for (let z = 0; z < all_tasks_in_one_project.length; z++) {
            //   if( all_tasks_in_one_project[z].project == project[j] ){
                var task_id = all_tasks_in_one_project[z].ID
                var parentid = all_tasks_in_one_project[z].parentid
                var tasktags = all_tasks_in_one_project[z].tasktags
                var obj = JSON.parse(tasktags)
                var urgenttag = obj["urgenttag"]
                var keyproblemtag = obj["keyproblemtag"]
                
                // var urgenttag = obj[""]
                var task_li = document.createElement("li")
                task_li.setAttribute("id",`${task_id}`)
                task_li.setAttribute("class","task_li")
                task_li.setAttribute("parentid",`${parentid}`)
                task_li.setAttribute("urgenttag",`${urgenttag}`)
                var task_div = document.createElement("div")
                var task_span = document.createElement("span")
                task_span.setAttribute("urgenttag",`${urgenttag}`)
                task_span.setAttribute("keyproblemtag",`${keyproblemtag}`)
                var add_sontask_button = document.createElement("input")
                add_sontask_button.setAttribute("class","add_sontask_button")
                add_sontask_button.type = "image"
                add_sontask_button.width = "12"
                add_sontask_button.height = "12"
                add_sontask_button.setAttribute("src","https://test-1255367272.cos.ap-chengdu.myqcloud.com/plus.svg")

                var add_task2today_button = document.createElement("button")
                add_task2today_button.setAttribute("class","add_task2today_button")
                // add_task2today_button.textContent = "Today"
                add_task2today_button.innerHTML = configure_options.today_word;

                var add_task2tomorrow_button = document.createElement("button")
                add_task2tomorrow_button.setAttribute("class","add_task2tomorrow_button")
                add_task2tomorrow_button.innerHTML = configure_options.tomorrow_word

                var giveup_task_button = document.createElement("button")
                giveup_task_button.setAttribute("class","giveup_task_button")
                // giveup_task_button.textContent = "Giveup"
                 
                giveup_task_button.innerHTML = configure_options.giveup_word;
                task_span.textContent = all_tasks_in_one_project[z].task
                task_div.appendChild(task_span)
                // task_div.appendChild(add_sontask_button)
                task_div.appendChild(add_task2today_button)
                task_div.appendChild(add_task2tomorrow_button)
                task_div.appendChild(giveup_task_button)   
                task_li.appendChild(task_div)
                task_ul.appendChild(task_li)
            //   }
            }
        }
           project_li.appendChild(task_ul)
           goal_li.appendChild(project_ul)
        // temp_project[`${temp_project}`] = temp_tasks
        }
        // all_unfinished_tasks_tree[`${temp_goal}`] = temp_project
        
        goal_ul.appendChild(goal_li)
    }
    document.getElementById("goal_shown_and_edit").appendChild(goal_ul)
}
function get_all_unfinished_tasks_list(SRC_API){
   axios.get(SRC_API).then(
     response=>{
       var data = response.data
           console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
      console.log(data);
      // console.log(status);
      // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
      all_unfinished_tasks = data.goals;
      console.log("I AM PRINTING THE ALL UNFINISHED TASK")
      console.log(all_unfinished_tasks)
      if (all_unfinished_tasks == null) {
          return
      }
      else{
          // return all_unfinished_tasks;
      document.getElementById("goal_shown_and_edit").innerHTML = ""
      create_goal_project_task_div()
      }
    

     }
   ).catch(error=>{console.log(error)})
  
    }

function randomString(e) {    
    e = e || 32;
    var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
    a = t.length,
    n = "";
    for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n
}


export default {
  name: 'reference',
  props: {
    msg: String
  },
  mounted(){
    get_all_unfinished_tasks_list("http://127.0.0.1:8081/v1/goalsgraph")
    
    var _originthis = this
    // 把任务调度到今天
    $(document).on("click",".add_task2today_button",function(){
    // alert("调试到今天的事件被触发")
    var clicked_taskid = $(this).parent().parent().attr("id")
    var task_li_div = document.getElementById(clicked_taskid)

    console.log("==========这里是所有要调度到今天任务的ID=============")
    console.log(clicked_taskid)
    // console.log(giveup_taskids)
    
    var updatedinfo =
        {
            "ifdissect":"no",
            "deadline":"unspecified",
            "starttime":"unspecified",
            "endtime":"unspecified",
            "tasktagsorigin":"unspecified",
            "parentid":"unspecified",
            "timedevotedto_a_task":0,
            "goalcode":"xxx",
            "client":"gtdcli",
            "taglight":"no",
            "note":"unspecified",
            "reviewalgolight":"no",
            "reviewalgo":{},
            "parentproject":"unspecified",
            "id":clicked_taskid,
            "place":"unspecified",
            "finishtime":"unspecified",
            "inbox":"nocontent",
            "project":"inbox",
            "plantime":"today",
            "taskstatus":"unfinished",
            "tasktags":{}
             
        }
          // console.log(updatedinfo)
        axios.post("http://127.0.0.1:8081/v1/update",updatedinfo,{withCredentials:true}).then(
          response=>{

            var data = response.data
            console.log(data)
              // alert(data); 
              task_li_div.outerHTML = ""
              _originthis.$store.commit('setshowtodaystate', { reshowtoday: randomString(6) }); 

          }

        ).catch(
          error=>{console.log(error)}
        )


          // $.ajax({
          //   type: "POST",
          //   url: "/v1/update",
          //   // The key needs to match your method's input parameter (case-sensitive).
          //   data: JSON.stringify(updatedinfo),
          //   contentType: "application/json; charset=utf-8",
          //   dataType: "json",
          //   success: function (data) { 
          //   //   alert("我成功把任务调度到今天")
          //     console.log(data)
          //     // alert(data); 
          //     task_li_div.outerHTML = ""
          //     show_today_tree()
          //   },
          //   failure: function (errMsg) {
          //     console.log("this is erro")
          //     alert(errMsg);
          //   }
          // })
          
  })







  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../css/goaltree.css';
</style>
